@import '~@which/seatbelt/src/styles/styles';
@import '../../PersonalisedMemberBenefits.module.scss';

$benefits-row-padding: $sb-spacing-s;
$section-vertical-gap: $sb-spacing-m;

.panel {
  overflow: visible;
  &HeadingWrapper {
    display: flex;
    flex-direction: column;
  }
  &Title {
    margin-bottom: calc($section-vertical-gap - $benefits-row-padding);
  }

  &Section {
    margin-top: $sb-spacing-m;
  }
}
