@import '~@which/seatbelt/src/styles/styles';

.section {
  padding: $sb-spacing-xl $sb-spacing-m;
  background: $sb-color-black-01;

  @media only screen and (min-width: 767px) {
    padding: $sb-spacing-xl 77px;
  }

  @media only screen and (min-width: 1439px) {
    padding: $sb-spacing-2xl auto;
  }
}

.card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background: $sb-color-text-white;
  border: sb-border($sb-border-width-1, 'sb-color-black-20');
  border-radius: $sb-border-radius-m;
  padding: $sb-spacing-m;
  max-width: 894px;
  justify-self: center;
  margin: auto;

  @media only screen and (min-width: 767px) {
    flex-direction: row-reverse;
    gap: $sb-spacing-m;
  }
}

.image {
  margin: 0;

  @media only screen and (min-width: 767px) {
    flex: 1;
  }

  img {
    max-height: 144px;
    width: auto;
    justify-self: center;
    margin: auto;

    @media only screen and (min-width: 767px) {
      max-height: 200px;
    }
  }
}

.textContent {
  flex: 1;
}

.heading {
  font-family: $sb-font-family-medium;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: normal;
  margin: $sb-spacing-m 0 $sb-spacing-s;

  @media only screen and (min-width: 767px) {
    font-size: 22px;
  }
}

.description {
  margin: $sb-spacing-s 0;
}

.button {
  margin: $sb-spacing-m 0 $sb-spacing-s;
}
