@import '~@which/seatbelt/src/styles/styles';

.cardImg {
  flex-basis: 138px;
}

.productReviewCardSmall {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $sb-spacing-m;
}

.productReviewCardLarge {
  .title {
    margin-top: $sb-spacing-m;
    margin-bottom: $sb-spacing-xs;
  }
}

.sectionSpacing {
  padding: $sb-spacing-xl 0;

  @include sb-respond-to(large) {
    padding: $sb-spacing-3xl 0;
  }
}

.cardTitle {
  display: grid;
  grid-template-rows: 3fr;

  > div {
    display: flex;
    align-items: center;
  }
  > :last-child {
    padding-top: $sb-spacing-s;
  }
}

.feedbackSurvery {
  padding: $sb-spacing-m;
}

.productDetails {
  display: flex;
  align-items: baseline;
  gap: $sb-spacing-s;
}
