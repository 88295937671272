@import '~@which/seatbelt/src/styles/styles';

.list {
  @include sb-reset-list;

  display: grid;
  padding-top: $sb-spacing-s;

  grid-template-columns: 1fr 1fr;
  gap: $sb-spacing-l 22px;

  @include sb-respond-to(medium-up) {
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    gap: $sb-spacing-l $sb-spacing-2xl;
  }

  @include sb-respond-to(large) {
    grid-template-columns: revert;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.listItem {
  @include sb-respond-to(large) {
    flex: 0 1 175px;
  }
}

.link {
  display: block;
  text-align: center;
  outline-offset: 12px;
}

.imageContainer {
  margin-bottom: $sb-spacing-xs;
}

.image {
  @include sb-respond-to(large) {
    background-color: transparent;
  }
}
